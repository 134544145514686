<script type="text/javascript"></script>

<template>
  <div style = "height:100%; background-color: #ffffff" class="d-flex justify-content-center align-items-center">
   <div class = "container" >

    <div id="signuppage"> 
      <p class="logo"><a href="https://flowe.io" ><img src="@/static/img/flowe.png" class="imglogo"></a></p>

      <div class="pagetitle" v-if="signup.success">
        <h2 class = "text-center">Thank you for signing up!</h2>
        <p class = "text-center" >You're one step closer to better management.<br>
          To continue the registration process,<br> please check your email.</p>
          <p class="text-center">If  you didn't get the email, just <a href="#" class="text-primary" v-on:click.prevent="tryresendemail()" v-if="resendmail!='sending'">click here</a> <span v-if="resendmail=='sending'">click here</span><br>and we will resend it immediately.</p>

          <p class="text-center"> <i class="fa fa-circle-o-notch fa-spin" v-if="resendmail=='sending'"></i> </p>
          <p class="text-success text-center" role="alert" v-if="resendmail == 'sent'">Email sent, please follow the link from the email.</p>
          <p class="text-danger text-center" role="alert" v-if="resendmail == 'failed'">Mail not sent, try again</p> 

        </div>

        <template v-if="!signup.success">

          <div class="pagetitle">
            <h2 class = "text-center">Sign up</h2>
            <p class="text-center">Completely free. Immediate access.</p>
          </div> 

          <div class="form_wrapper">
            <form onsubmit="return false">
              <div class="form-group">
                <label>Email</label>
                <input type="text" class="form-control" placeholder="email" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" name="signupemail" autocomplete="off" v-model="signup.email"> 
                   <span class="response text-danger" v-if="signup.errors.emailinvalid || signup.errors.emailrequired">Please enter a valid email address</span>
                  <span class="response text-danger" v-if="signup.errors.userexists">
                    Looks like you've already registered with that email address. Click the link below to
                    sign in with this account, or enter a different email to register a new account.
                  </span>

              </div>

              <div class="form-group" style = "padding-top: 20px"> 
                <button v-if="status=='idle'" type="submit" @click.stop.prevent="onRecaptcha()" class="btn btn-primary btn-block btn-lg g-recaptcha">Sign up</button>
                <button v-if="status=='working'" type="button" class="btn btn-primary btn-block btn-lg disabled" disabled>Sign up <i class="fa fa-circle-o-notch fa-spin"></i></button>
              </div>
            </form>

            <div class="form-group">
              <p class="text-center">or</p>
            </div>

            <div class="form-group" >

              <p class="text-danger" v-if="googlesigninexists">Looks like the email address associated with that Google account has already been registered. Click the link below to sign in with this account, or try signing up with a different Google account.</p>

              <button :class="{hide:invitationcodevalid}" type="button" class="btn btn-block btn-lg btn-google" @click="checkvalid()"> <img src="@/static/img/glogo.png" style="width: 24px; height: 24px;" >Sign up with Google</button>


             <GoogleLogin :class="{hide:!invitationcodevalid}" :params="params" :onSuccess="onSuccess" :onFailure="onFailure" class="btn btn-block btn-lg btn-google"> <img src="@/static/img/glogo.png" style="width: 24px; height: 24px;">Sign up with Google</GoogleLogin>   

             
           </div>

            <!-- <div class="form-group">
                 <button class="btn btn-block btn-lg btn-secondary signUpwithGoogle" id="signupwithgooglesplash">
                    <span class="align-middle"><i class="fa fa-google pull-left mt-1" aria-hidden="true"></i> Sign up with Google</span>
                 </button> 
               </div> -->

               <p class="text-center">
                <router-link class="actionwarning" to="sign-in">Already have an account? Sign in</router-link>
              </p>

            </div>

            <p class="text-center">By clicking sign up, you agree to our <br>
              <a class="text-primary" href="https://flowe.io/termsofservice">Terms of Service</a> and <a class="text-primary" href="https://flowe.io/privacy">Privacy Policy</a></p>

          </template>

        </div>
      </div>
    </div>
  </template>	
  <style type="text/css" scoped>

  :root{
    /* --G700: #157815; */
    --N900: #313338;
  }

  #app{
    background-color: #fff;
  }

  html,
  body {
    color: var(--N900);
    height: 100%;
  }

  body {
    padding-top: 10%;
    min-width: 360px;
  }


  h1, h2{ text-align: center; }

  .pagetitle{
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
    margin-bottom: 1rem;
    margin-top: 3%;
  }


  .form_wrapper{
    margin: 0 auto;
    max-width: 350px;
  }


  #signuppage2 .form_wrapper{ max-width: 400px; }

  #responsepage .signuppage .pagetitle{
    max-width: 500px;
  }

  .btn-google img{ 
    width: 24px;
    height: 24px;
    margin-right:.3em; 
  }

  p.logo .imglogo{ 
    width: 200px; 
  }
  p.logo{
    text-align: center;
    width: 100%;
    margin: auto;
  }


</style>
<script>

  import Signinapi from '@/services/api/signin'
  import GoogleLogin from 'vue-google-login'
  import Userapi from '@/services/api/users'

  export default{
    data(){
     return{
      status : 'idle',
      userid : null,
      signup : { email:"", invitationcode:"", signupisvalid:true, emailisvalid:true, invitationcodeisvalid:false, errors:{}, success:false },
      googlesigninexists : false,
      params: {
        client_id: "957046757712-8onubrnl55fk21ihr4m5hd243fcbkaq2.apps.googleusercontent.com",
        class : "btn btn-block btn-lg btn-google"
      },
      resendmail : "",
      remember : false,
      invitationcodevalid : true,
      recaptchatoken : ""
    }

  },
  computed: {

  },
  filters: {},
  mounted() {


    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LcllU0gAAAAAJaHbJjZ8VizqdgjpyoTeYTv2xQs')
    document.head.appendChild(recaptchaScript)

    //this.usetoken( localStorage.token )
    document.title ='Flowe | Sign up'
    $(function () {
      $('[data-toggle="popover"]').popover()
    })

    $('#preloader').addClass('hide')
  },
  methods: {


    onRecaptcha : function() {

      let self = this
      //e.preventDefault();
      grecaptcha.ready(function() {
        grecaptcha.execute('6LcllU0gAAAAAJaHbJjZ8VizqdgjpyoTeYTv2xQs', {action: 'submit'}).then(function(token) {
            // Add your logic to submit to your backend server here.

            //console.log( token )
            self.recaptchatoken = token
            self.dosignup('')
        });
      });

      //console.log( token )
      //document.getElementById("demo-form").submit();
      //v-on:click="retrievepassword('')"
    },

    checkvalid(){

      this.googlesigninexists = false
      this.signup.errors = {}
      if( !this.invitationcodevalid ){
        this.signup.errors = { invitationcoderequired:true }
      }
      
    },

    /*usetoken2( token ){

      localStorage.remember = ""

            let self = this

            this.pageloaded = false

            if( token != "" ){

              Userapi.Details( token )
              .then( function(response){

                if( response.data.ok ){

                  if( self.remember ){
                    localStorage.remember = "yes"
                  }

                  localStorage.token = token
                  localStorage.userdetails = JSON.stringify( response.data.user )
                  //location.href="/boards"
                  self.$router.push("/boards")
                  
                }else{
                  self.pageloaded = true
                }

                $('#preloader').addClass('hide')

              })
              .catch( () => {
               //self.pageloaded = true

               $('#preloader').addClass('hide')
              })
              .finally( () => {
               //self.pageloaded = true

               $('#preloader').addClass('hide')
              })


            }else{
              this.pageloaded = true
              $('#preloader').addClass('hide')
            }



          },
*/
    tryresendemail(){


      let self = this

      self.resendmail = 'sending'

      Signinapi.Signup( { email: self.signup.email, invitationcode: "joinflowe" } )
      .then( function(response){
        if( response.data.ok ){
          self.resendmail = 'sent'
        }else{
          self.resendmail = 'failed'
        }
      })
      .catch( () =>{
        self.resendmail = 'failed'
      })


    },

    onSuccess( googleUser ){

      let self = this

      this.signup.errors = {}

                //var profile = googleUser.getBasicProfile();      
                var id_token = googleUser.getAuthResponse().id_token;
                
                Signinapi.Signupwithgoogle( id_token )
                .then( function(response){
                  
                  if( response.data.ok ){

                    self.remember = true

                    self.$cookie.set('usertoken', response.data.response, { expires: '1M' } );
                    //self.$cookie.set('usertoken', response.data.response);
                    //self.$router.push('/boards')
                    location.href='/boards'

                    //localStorage.token = response.data.response
                    //self.usetoken( response.data.response )  
                  }else{

                    if( response.data.error == "googlesigninexists" ){
                      self.googlesigninexists = true
                    }
                  }

                })
                .catch(error => console.log(error))
                .finally( () => {

                  self.status = 'idle'

                })

              },
    onFailure( rep ){

                this.signup.errors = {}
                console.log(rep, "error")
              },
    dosignup : function(){

      this.googlesigninexists = false
      this.signup.errors = {}

                let self = this

                self.status = 'working'

                Signinapi.Signup( { email: self.signup.email, invitationcode: "joinflowe", "retoken":this.recaptchatoken } )
                .then( function(response){
                  if( response.data.ok ){
                    self.signup.success = true
                  }else if( response.data.errormessages ){

                    //console.log(response.data.errormessages)
                    self.signup.errors = response.data.errormessages
                    //signinisvalid
                    //console.log( response.data.ErrorMessage.email, response.data.ErrorMessage.password )
                  }
                })
                .catch(error => console.log(error))
                .finally( () => {

                  self.status = 'idle'

                  //do everything
                  //self.signin  = { password:"", signinisvalid:true, emailisvalid:true, signinlocked:false, passwordempty:false }
                })


              }
            },
            components : { GoogleLogin }
          }
        </script>